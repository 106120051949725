import { connect } from "react-redux";
import { getYandexAccounts, saveAccounts } from "../../modules/accounts";

import AccountSelector from "../../components/AccountSelector";

const mapStateToProps = state => ({
  dropdownOptions: state.accounts.yandex,
  network: "yandex",
  name: "Yandex",
});

const YandexAccountSelector = connect(
  mapStateToProps,
  {
    getAccounts: getYandexAccounts,
    saveAccounts,
  }
)(AccountSelector);

export default YandexAccountSelector;
