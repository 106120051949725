import api from "../helpers/api";

const actionPrefix = "reportingConfig/authorize/";

const status = {
  expired: true,
  authorized: false,
};

const initialState = {
  facebook: Object.assign(status),
  adwords: Object.assign(status),
  bing: Object.assign(status),
  yandex: Object.assign(status),
  seznam: Object.assign(status),
};

export const AUTHORIZE_CHECK_SUCCESS = `${actionPrefix}AUTHORIZE_CHECK_SUCCESS`;
export const AUTHORIZE_CHECK_FAILURE = `${actionPrefix}AUTHORIZE_CHECK_FAILURE`;

export const authorizeCheck = network => dispatch =>
  api
    .post(`/api/auth/${network}/status`)
    .then(response =>
      dispatch({
        type: AUTHORIZE_CHECK_SUCCESS,
        payload: response.data,
        network: network,
      })
    )
    .catch(error =>
      dispatch({ type: AUTHORIZE_CHECK_FAILURE, payload: error })
    );

const authorize = (state = initialState, action) => {
  switch (action.type) {
    case AUTHORIZE_CHECK_SUCCESS:
      return {
        ...state,
        [action.network]: { ...action.payload },
      };
    case AUTHORIZE_CHECK_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default authorize;
