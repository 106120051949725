import { combineReducers } from "redux";
import authorize from "./authorize";
import accounts from "./accounts";
import exportConfiguration from "./exportConfiguration";
import login from "./login";

const reducers = combineReducers({
  authorize,
  accounts,
  login,
  exportConfiguration,
});

export default reducers;
