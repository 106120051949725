import React, { Component } from "react";
import { Form, Divider, Grid, Button } from "semantic-ui-react";

class NetworkConfiguration extends Component {
  constructor(props) {
    super(props);

    this.onTextAreaChange = this.onTextAreaChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateAccounts = this.updateAccounts.bind(this);
  }

  componentDidMount() {
    this.props.getConfiguration();
  }

  updateAccounts(e, data) {
    const { updateFields, network } = this.props;
    updateFields(
      {
        accounts: data.value,
      },
      network
    );
  }

  onTextAreaChange(e, { name, value }) {
    const { updateFields, network } = this.props;
    if (name === "reportFields") {
      updateFields({ reportFields: value }, network);
    }

    updateFields(
      {
        [name]: value.split(","),
      },
      network
    );
  }

  onInputChange(e, { name, value }) {
    const { updateFields, network, slack } = this.props;
    updateFields(
      {
        slack: { ...slack, [name]: value },
      },
      network
    );
  }

  onSubmit() {
    const {
      id,
      reportFields,
      reportPeriod,
      validCountries,
      validStages,
      validTypes,
      slack,
      accounts,
      network,
      updateConfiguration,
      addConfiguration,
    } = this.props;

    const config = {
      reportFields,
      reportPeriod,
      validCountries,
      validStages,
      validTypes,
      slack,
      accounts,
    };

    if (id) {
      updateConfiguration({
        config,
        network,
      });
    } else {
      addConfiguration({
        config,
        network,
      });
    }
  }

  render() {
    const {
      networkName,
      children,
      reportFields,
      validCountries,
      validStages,
      validTypes,
      slack,
      accounts = [],
    } = this.props;

    const { username = "", icon = "", channel = "" } = slack ? slack : {};

    return (
      <div>
        <h3>{networkName} configuration</h3>
        <Form>
          <Grid columns={3} divided>
            <Grid.Row>
              <Grid.Column>
                <h4>Accounts</h4>
                {React.Children.map(children, child => {
                  return React.cloneElement(child, {
                    updateAccounts: this.updateAccounts,
                    value: accounts,
                  });
                })}
                <h4>Report Fields</h4>
                <Form.Field>
                  <Form.TextArea
                    rows={7}
                    placeholder="Report fields"
                    name="reportFields"
                    value={reportFields}
                    onChange={this.onTextAreaChange}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <h4>Valid Stages</h4>
                <Form.Field>
                  <Form.TextArea
                    rows={7}
                    placeholder="Valid Stages, format: Awareness,Consideration..."
                    name="validStages"
                    value={validStages}
                    onChange={this.onTextAreaChange}
                  />
                </Form.Field>
                <h4>Valid Countries</h4>
                <Form.Field>
                  <Form.TextArea
                    rows={7}
                    placeholder="Valid Countries, format: AU,HR,BR etc..."
                    name="validCountries"
                    value={validCountries}
                    onChange={this.onTextAreaChange}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <h4>Valid Types</h4>
                <Form.Field>
                  <Form.TextArea
                    rows={7}
                    placeholder="Valid Types"
                    name="validTypes"
                    value={validTypes}
                    onChange={this.onTextAreaChange}
                  />
                </Form.Field>
                <h4>Slack Network Settings</h4>
                <Form.Field>
                  <Form.Input
                    size="mini"
                    label="Username"
                    name="username"
                    value={username}
                    onChange={this.onInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    size="mini"
                    label="Channel"
                    name="channel"
                    value={channel}
                    onChange={this.onInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    size="mini"
                    label="Icon"
                    name="icon"
                    placeholder=":network"
                    value={icon}
                    onChange={this.onInputChange}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Button fluid floated="right" onClick={this.onSubmit}>
                Submit
              </Button>
            </Grid.Row>
          </Grid>
        </Form>
        <Divider />
      </div>
    );
  }
}

export default NetworkConfiguration;
