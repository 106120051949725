import React, { Component } from "react";
import { Dropdown, Divider } from "semantic-ui-react";

class AccountDropdown extends Component {
  componentDidMount() {
    this.props.getAccounts();
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          placeholder={`${this.props.name} Accounts`}
          fluid
          multiple
          selection
          options={this.props.dropdownOptions}
          onChange={this.props.updateAccounts}
          value={this.props.value}
        />
        <Divider hidden />
      </React.Fragment>
    );
  }
}

export default AccountDropdown;
