import { connect } from "react-redux";
import {
  addConfiguration,
  getConfiguration,
  updateConfiguration,
  updateFields,
} from "../../modules/exportConfiguration";
import NetworkConfiguration from "../../components/NetworkConfiguration";

const mapStateToProps = state => ({
  ...state.exportConfiguration.facebook,
  network: "facebook",
  networkName: "Facebook",
});

const Facebook = connect(
  mapStateToProps,
  {
    addConfiguration,
    getConfiguration,
    updateConfiguration,
    updateFields,
  }
)(NetworkConfiguration);

export default Facebook;
