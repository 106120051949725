import React from "react";
import AuthorizeButton from "./AuthorizeButton";

const Connections = () => {
  const facebookUrl = `/api/auth/facebook`;
  const bingUrl = `/api/auth/bing`;
  const yandexUrl = `/api/auth/yandex`;

  return (
    <div>
      <h1>Connections</h1>
      <AuthorizeButton
        url={facebookUrl}
        type="Facebook Ads"
        network="facebook"
      />
      <AuthorizeButton url={bingUrl} type="Bing" network="bing" />
      <AuthorizeButton url={yandexUrl} type="Yandex" network="yandex" />
    </div>
  );
};

export default Connections;
