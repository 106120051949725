import React from "react";
import { Link, Switch, Route } from "react-router-dom";
import { Grid, Menu, Divider, Container } from "semantic-ui-react";
import Connections from "./Connections";
import NetworkConfigurations from "./NetworkConfigurations";

const Main = () => {
  return (
    <div>
      <Divider hidden />
      <Container>
        <h2>Export Configuration</h2>
      </Container>
      <Divider />
      <Divider hidden />
      <Grid container>
        <Grid.Column width={3}>
          <Menu pointing secondary size="large" vertical fluid>
            <Menu.Item>
              <Link to="/connections">Connections</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/networks">Network Configuration</Link>
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column width={12}>
          <Switch>
            <Route exact path="/" component={Connections} />
            <Route path="/networks" component={NetworkConfigurations} />
          </Switch>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Main;
