import { connect } from "react-redux";
import { getFacebookAccounts, saveAccounts } from "../../modules/accounts";
import AccountSelector from "../../components/AccountSelector";

const mapStateToProps = state => ({
  dropdownOptions: state.accounts.facebook,
  network: "facebook",
  name: "Facebook",
});

const FacebookAccountSelector = connect(
  mapStateToProps,
  {
    getAccounts: getFacebookAccounts,
    saveAccounts,
  }
)(AccountSelector);

export default FacebookAccountSelector;
