import _forEach from "lodash/forEach";
import api from "../helpers/api";

const actionPrefix = "reportingConfig/globalConfiguration/";

export const EXPORT_CONFIGURATION_GET_SUCCESS = `${actionPrefix}EXPORT_CONFIGURATION_GET_SUCCESS`;
export const EXPORT_CONFIGURATION_GET_FAILURE = `${actionPrefix}EXPORT_CONFIGURATION_GET_FAILURE`;
export const EXPORT_CONFIGURATION_UPDATE_SUCCESS = `${actionPrefix}EXPORT_CONFIGURATION_UPDATE_SUCCESS`;
export const EXPORT_CONFIGURATION_UPDATE_FAILURE = `${actionPrefix}EXPORT_CONFIGURATION_UPDATE_FAILURE`;
export const EXPORT_CONFIGURATION_ADD_SUCCESS = `${actionPrefix}EXPORT_CONFIGURATION_ADD_SUCCESS`;
export const EXPORT_CONFIGURATION_ADD_FAILURE = `${actionPrefix}EXPORT_CONFIGURATION_ADD_FAILURE`;

export const EXPORT_UPDATE_FIELDS = `${actionPrefix}EXPORT_UPDATE_FIELDS`;
export const EXPORT_UPDATE_INPUT_FIELDS = `${actionPrefix}EXPORT_UPDATE_INPUT_FIELDS`;

export const updateFields = (data, network) => dispatch => {
  dispatch({
    type: EXPORT_UPDATE_FIELDS,
    data: data,
    network: network,
  });
};

export const addConfiguration = params => dispatch =>
  api
    .post("/api/configurations/export", params)
    .then(response =>
      dispatch({
        type: EXPORT_CONFIGURATION_ADD_SUCCESS,
        config: response.data,
        network: params.network,
      })
    )
    .catch(error =>
      dispatch({ type: EXPORT_CONFIGURATION_ADD_FAILURE, payload: error })
    );

export const updateConfiguration = params => dispatch =>
  api
    .put("/api/configurations/export", params)
    .then(response =>
      dispatch({
        type: EXPORT_CONFIGURATION_UPDATE_SUCCESS,
        config: response.data,
        network: params.network,
      })
    )
    .catch(error =>
      dispatch({ type: EXPORT_CONFIGURATION_UPDATE_FAILURE, payload: error })
    );

export const getConfiguration = () => dispatch =>
  api
    .get(`/api/configurations/export`)
    .then(response =>
      dispatch({
        type: EXPORT_CONFIGURATION_GET_SUCCESS,
        configs: response.data,
      })
    )
    .catch(error =>
      dispatch({ type: EXPORT_CONFIGURATION_GET_FAILURE, payload: error })
    );

const config = {
  reportFields: "",
  reportPeriod: ["yesterday"],
  validCountries: "",
  validStages: "",
  validTypes: "",
  slack: {
    username: "",
    channel: "",
    link_names: true,
    icon: "",
  },
  accounts: [],
};

const initialState = {
  bing: { ...config },
  yandex: { ...config },
  facebook: { ...config },
};

const generateConfigs = (state, configs) => {
  const newState = {};
  _forEach(configs, (config, network) => {
    newState[network] = {
      ...state[network],
      ...config,
    };
  });

  return newState;
};

const exportConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_CONFIGURATION_ADD_SUCCESS:
      return {
        ...state,
        [action.network]: action.config,
      };
    case EXPORT_CONFIGURATION_ADD_FAILURE:
      return {
        ...state,
        error: true,
      };
    case EXPORT_CONFIGURATION_GET_SUCCESS:
      return {
        ...generateConfigs(state, action.configs),
      };
    case EXPORT_CONFIGURATION_GET_FAILURE:
      return {
        ...state,
      };
    case EXPORT_CONFIGURATION_UPDATE_SUCCESS:
      return {
        ...state,
        [action.network]: action.config,
      };
    case EXPORT_CONFIGURATION_UPDATE_FAILURE:
      return {
        ...state,
        error: true,
      };
    case EXPORT_UPDATE_FIELDS:
      return {
        ...state,
        [action.network]: {
          ...state[action.network],
          ...action.data,
        },
      };
    default:
      return state;
  }
};

export default exportConfiguration;
