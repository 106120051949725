import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { authorizeCheck } from "../modules/authorize";

class AuthorizeButton extends Component {
  componentDidMount() {
    this.props.authorizeCheck(this.props.network);
  }

  render() {
    const color =
      this.props.networks[this.props.network].expired === true
        ? "red"
        : "green";
    const icon =
      this.props.networks[this.props.network].expired === true
        ? "window close outline"
        : "check circle outline";

    return (
      <div>
        <Button
          basic
          floated="left"
          size="medium"
          type="submit"
          color={color}
          onClick={() => {
            window.open(this.props.url, "_self");
          }}
        >
          <Icon name={icon} />
          {this.props.type}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  networks: state.authorize,
});

AuthorizeButton = connect(
  mapStateToProps,
  { authorizeCheck }
)(AuthorizeButton);

export default AuthorizeButton;
