import { connect } from "react-redux";
import { getBingAccounts, saveAccounts } from "../../modules/accounts";
import AccountSelector from "../../components/AccountSelector";

const mapStateToProps = state => ({
  dropdownOptions: state.accounts.bing,
  network: "bing",
  name: "Bing",
});

const BingAccountSelector = connect(
  mapStateToProps,
  {
    getAccounts: getBingAccounts,
    saveAccounts,
  }
)(AccountSelector);

export default BingAccountSelector;
