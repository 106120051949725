import React from "react";
import Facebook from "../containers/NetworkConfigurations/Facebook";
import Bing from "../containers/NetworkConfigurations/Bing";
import Yandex from "../containers/NetworkConfigurations/Yandex";
import FacebookAccountSelector from "../containers/Selectors/FacebookAccountSelector";
import BingAccountSelector from "../containers/Selectors/BingAccountSelector";
import YandexAccountSelector from "../containers/Selectors/YandexAccountSelector";

const NetworkConfigurations = () => (
  <React.Fragment>
    <Facebook>
      <FacebookAccountSelector />
    </Facebook>
    <Bing>
      <BingAccountSelector />
    </Bing>
    <Yandex>
      <YandexAccountSelector />
    </Yandex>
  </React.Fragment>
);

export default NetworkConfigurations;
