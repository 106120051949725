import React from "react";
import { Button, Icon } from "semantic-ui-react";

const Login = ({ url }) => {
  return (
    <div className="center">
      <h2>Export Configuration</h2>
      <Button onClick={url} size="large" color="google plus">
        <Icon name="google plus" /> Login
      </Button>
    </div>
  );
};

export default Login;
