import { connect } from "react-redux";
import {
  addConfiguration,
  getConfiguration,
  updateConfiguration,
  updateFields,
} from "../../modules/exportConfiguration";
import NetworkConfiguration from "../../components/NetworkConfiguration";

const mapStateToProps = state => ({
  ...state.exportConfiguration.bing,
  network: "bing",
  networkName: "Bing",
});

const Bing = connect(
  mapStateToProps,
  {
    addConfiguration,
    getConfiguration,
    updateConfiguration,
    updateFields,
  }
)(NetworkConfiguration);

export default Bing;
