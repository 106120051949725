import _map from "lodash/map";
import api from "../helpers/api";

const actionPrefix = "reportingConfig/accounts/";

const initialState = {
  facebook: [],
  adwords: [],
  bing: [],
  seznam: [],
  yandex: [],
};

export const ADACCOUNTS_SAVE_SUCCESS = `${actionPrefix}ADACCOUNTS_SAVE_SUCCESS`;
export const ADACCOUNTS_SAVE_FAILURE = `${actionPrefix}ADACCOUNTS_SAVE_FAILURE`;
export const ADACCOUNTS_DELETE_SUCCESS = `${actionPrefix}ADACCOUNTS_DELETE_SUCCESS`;
export const ADACCOUNTS_DELETE_FAILURE = `${actionPrefix}ADACCOUNTS_DELETE_FAILURE`;

export const FACEBOOK_ADACCOUNTS_SUCCESS = `${actionPrefix}FACEBOOK_ADACCOUNTS_SUCCESS`;
export const FACEBOOK_ADACCOUNTS_FAILURE = `${actionPrefix}FACEBOOK_ADACCOUNTS_FAILURE`;

export const ADWORDS_ADACCOUNTS_SUCCESS = `${actionPrefix}ADWORDS_ADACCOUNTS_SUCCESS`;
export const ADWORDS_ADACCOUNTS_FAILURE = `${actionPrefix}ADWORDS_ADACCOUNTS_FAILURE`;

export const BING_ADACCOUNTS_SUCCESS = `${actionPrefix}BING_ADACCOUNTS_SUCCESS`;
export const BING_ADACCOUNTS_FAILURE = `${actionPrefix}BING_ADACCOUNTS_FAILURE`;

export const SEZNAM_ADACCOUNTS_SUCCESS = `${actionPrefix}SEZNAM_ADACCOUNTS_SUCCESS`;
export const SEZNAM_ADACCOUNTS_FAILURE = `${actionPrefix}SEZNAM_ADACCOUNTS_FAILURE`;

export const YANDEX_ADACCOUNTS_SUCCESS = `${actionPrefix}YANDEX_ADACCOUNTS_SUCCESS`;
export const YANDEX_ADACCOUNTS_FAILURE = `${actionPrefix}YANDEX_ADACCOUNTS_FAILURE`;

export const getFacebookAccounts = () => dispatch =>
  api
    .get("/api/networks/facebook/adaccounts")
    .then(response =>
      dispatch({ type: FACEBOOK_ADACCOUNTS_SUCCESS, payload: response.data })
    )
    .catch(error =>
      dispatch({ type: FACEBOOK_ADACCOUNTS_FAILURE, payload: error })
    );

export const getAdwordsAccounts = () => dispatch =>
  api
    .get("/api/networks/adwords/adaccounts")
    .then(response =>
      dispatch({ type: ADWORDS_ADACCOUNTS_SUCCESS, payload: response.data })
    )
    .catch(error =>
      dispatch({ type: ADWORDS_ADACCOUNTS_FAILURE, payload: error })
    );

export const getBingAccounts = () => dispatch =>
  api
    .get("/api/networks/bing/adaccounts")
    .then(response =>
      dispatch({ type: BING_ADACCOUNTS_SUCCESS, payload: response.data })
    )
    .catch(error =>
      dispatch({ type: BING_ADACCOUNTS_FAILURE, payload: error })
    );

export const getSeznamAccounts = () => dispatch =>
  api
    .get("/api/networks/seznam/adaccounts")
    .then(response =>
      dispatch({ type: SEZNAM_ADACCOUNTS_SUCCESS, payload: response.data })
    )
    .catch(error =>
      dispatch({ type: SEZNAM_ADACCOUNTS_FAILURE, payload: error })
    );

export const getYandexAccounts = () => dispatch =>
  api
    .get("/api/networks/yandex/adaccounts")
    .then(response =>
      dispatch({ type: YANDEX_ADACCOUNTS_SUCCESS, payload: response.data })
    )
    .catch(error =>
      dispatch({ type: YANDEX_ADACCOUNTS_FAILURE, payload: error })
    );

export const saveAccounts = params => dispatch =>
  api
    .post("/api/accounts", params)
    .then(response =>
      dispatch({ type: ADACCOUNTS_SAVE_SUCCESS, payload: response.data })
    )
    .catch(error =>
      dispatch({ type: ADACCOUNTS_SAVE_FAILURE, payload: error })
    );

export const deleteAccount = id => dispatch =>
  api
    .delete(`/api/accounts/${id}`)
    .then(response =>
      dispatch({ type: ADACCOUNTS_DELETE_SUCCESS, payload: response.data })
    )
    .catch(error =>
      dispatch({ type: ADACCOUNTS_DELETE_FAILURE, payload: error })
    );

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case FACEBOOK_ADACCOUNTS_SUCCESS:
      return {
        ...state,
        facebook: _map(action.payload.data, item => ({
          key: item.id,
          text: item.name,
          value: item.id,
          content: item.name,
        })),
      };
    case FACEBOOK_ADACCOUNTS_FAILURE:
      return {
        ...state,
      };
    case ADWORDS_ADACCOUNTS_SUCCESS:
      return {
        ...state,
        adwords: _map(action.payload, item => ({
          key: item.customerId,
          text: item.name,
          value: item.customerId,
          content: item.name,
        })),
      };
    case ADWORDS_ADACCOUNTS_FAILURE:
      return {
        ...state,
      };
    case SEZNAM_ADACCOUNTS_SUCCESS:
      return {
        ...state,
        seznam: _map(action.payload, item => ({
          key: item.userId,
          text: item.username,
          value: item.userId,
          content: item.username,
        })),
      };
    case SEZNAM_ADACCOUNTS_FAILURE:
      return {
        ...state,
      };
    case BING_ADACCOUNTS_SUCCESS:
      return {
        ...state,
        bing: _map(action.payload, item => ({
          key: item.Id,
          text: item.Name,
          value: item.Id,
          content: item.Name,
        })),
      };
    case BING_ADACCOUNTS_FAILURE:
      return {
        ...state,
      };
    case YANDEX_ADACCOUNTS_SUCCESS:
      return {
        ...state,
        yandex: _map(action.payload.data.Accounts, item => ({
          key: item.AccountID,
          text: item.Login,
          value: item.AccountID,
          content: item.Login,
        })),
      };
    case YANDEX_ADACCOUNTS_FAILURE:
      return {
        ...state,
      };
    case ADACCOUNTS_DELETE_SUCCESS:
      return {
        ...state,
      };
    case ADACCOUNTS_DELETE_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default accounts;
