import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { isUserLoggedin, loginAdmin } from "./modules/login";
import Main from "./components/Main";
import Login from "./components/Login";
import "./App.css";

class App extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(isUserLoggedin());
  }

  render() {
    const { adminLoggedIn, loader } = this.props.login;
    return (
      <div className="App">
        {loader && <Loader size="medium">Verify login...</Loader>}
        {adminLoggedIn ? <Main /> : <Login url={loginAdmin} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,
});

export default withRouter(connect(mapStateToProps)(App));
