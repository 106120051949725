import api from "../helpers/api";
import Cookie from "js-cookie";

const actionPrefix = "reportingConfig/login/";

export const LOGIN_ADMIN = `${actionPrefix}LOGIN_ADMIN`;
export const VERIFY_USER_LOGGED_IN_START = `${actionPrefix}VERIFY_USER_LOGGED_IN_START`;
export const VERIFY_USER_LOGGED_IN_SUCCES = `${actionPrefix}VERIFY_USER_LOGGED_IN_SUCCES`;
export const VERIFY_USER_LOGGED_IN_FAILURE = `${actionPrefix}VERIFY_USER_LOGGED_IN_FAILURE`;

export const loginAdmin = () => {
  const endPoint = process.env.REACT_APP_BACKEND || "";
  window.location = `${endPoint}api/exportLogin`;
};

export const isUserLoggedin = () => dispatch => {
  dispatch({ type: VERIFY_USER_LOGGED_IN_START });
  return api
    .get("/api/login/authenticated", {
      headers: { Authorization: Cookie.get("jwt_token") },
    })
    .then(response =>
      dispatch({
        type: VERIFY_USER_LOGGED_IN_SUCCES,
        authenticated: response.data.isAuthenticated,
      })
    )
    .catch(error =>
      dispatch({ type: VERIFY_USER_LOGGED_IN_FAILURE, payload: error })
    );
};

const initialState = {
  adminLoggedIn: false,
  loader: true,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_USER_LOGGED_IN_START:
      return {
        ...state,
        loader: true,
      };
    case VERIFY_USER_LOGGED_IN_SUCCES:
      return {
        ...state,
        adminLoggedIn: action.authenticated,
        loader: false,
      };
    case VERIFY_USER_LOGGED_IN_FAILURE:
      return {
        ...state,
        adminLoggedIn: false,
        loader: false,
      };
    default:
      return state;
  }
};

export default auth;
